import React from "react";

const TOS = () => {
  return (
    <div className="page_content">
      <main>
        <div className="row_1" style={{ marginTop: "8rem" }}>
          <div className="gutter_left"></div>

          <div className="main_content tos_main">
            <h1>GoWithGIG Terms of Service</h1>
            <h2> Introduction</h2>
            <p>
              <h3>Welcome to GoWithGIG!</h3> These Terms of Service ("Terms")
              govern your access and use of our car rental services
              ("Services"), applications, websites, content, products, and
              software (collectively, the "GoWithGIG Platform").{" "}
              <h3>Eligibility</h3> You must be at least 21 years old to use the
              GoWithGIG Platform. By agreeing to these Terms, you represent and
              warrant that you are at least 21 years old and capable of entering
              into legally binding contracts. <h3>Account Registration</h3> To
              use certain features of the GoWithGIG Platform, you must register
              for an account. You agree to provide accurate, current and
              complete information during the registration process.{" "}
              <h3>Vehicle Rental</h3>{" "}
              <ul>
                <li>
                  <b>Availability</b>: Vehicles are subject to availability and
                  we cannot guarantee that vehicles will always be available
                  when you wish to rent one.
                </li>{" "}
                <li>
                  <b>Requirements</b>: In order to rent a vehicle, you must meet
                  our eligibility requirements, which include having a valid
                  driver's license, a clean driving record, and appropriate
                  insurance coverage.
                </li>{" "}
                <li>
                  <b>Return</b>: Vehicles must be returned in the same condition
                  they were rented, subject to reasonable wear and tear.
                  Payments a. Rental Fees: You are responsible for paying all
                  fees due for your use of the GoWithGIG Platform.
                </li>{" "}
                <li>
                  <b>Overdue Charges</b>: If you fail to return a vehicle at the
                  agreed time, you may be charged additional fees.
                </li>
                <li>
                  <b>Damages</b>: You are responsible for any costs associated
                  with damage to a vehicle during your rental period.
                </li>
              </ul>{" "}
              <h3>Cancellation policy:</h3> Full refund if cancelled within an
              hour of booking or at least 24 hours prior to pickup. Fifty
              percent refund if cancelled less than 24 hours prior to pickup
              <h3>Refund policy:</h3> Full refund if reserved vehicle OR similar
              car not available at time of pickup. Prorated refund of unused
              time if car has mechanical issues and cannot be replaced within 3
              hours. <h3>Termination</h3> We may terminate your access to and
              use of the GoWithGIG Platform, at our sole discretion, at any time
              and without notice to you. <h3>Disclaimers</h3> THE GoWithGIG
              PLATFORM IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER
              EXPRESS OR IMPLIED. <h3>Limitation of Liability</h3> TO THE EXTENT
              PERMITTED BY LAW, GoWithGIG WILL NOT BE LIABLE FOR ANY INDIRECT,
              INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR
              IN CONNECTION WITH THESE TERMS OR THE GoWithGIG PLATFORM.
              <h3>Indemnity</h3> You agree to indemnify and hold GoWithGIG
              harmless from any and all claims, liabilities, damages, losses,
              and expenses, including, without limitation, reasonable legal and
              accounting fees, arising out of or in any way connected with your
              access to or use of the GoWithGIG Platform or your violation of
              these Terms. <h3>Governing Law & Jurisdiction</h3> These Terms are
              governed by and will be construed under the laws of the state in
              which GoWithGIG is incorporated, without regard to the conflicts
              of laws provisions thereof. Your agreement to these Terms also
              constitutes your agreement to the GoWithGIG Privacy Policy. <br />
              <br />
              <br />
              <br />
              <h4>Last updated on: June 28, 2023</h4>
            </p>
          </div>
          <div className="gutter_right"></div>
        </div>
      </main>
    </div>
  );
};

export default TOS;
