import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Switch, Route } from "react-router-dom";

import App_Navbar from "./components/appNavbar";
import Footer from "./components/footer";
import Home from "./components/home.js";
import Apply from "./components/apply.js";
import Dashboard from "./components/dashboard.js";
import UserDashboard from "./components/user.js";
import AboutUs from "./components/aboutUs.js";
import ContactUs from "./components/contactUs.js";
import TOS from "./pages/TOS";
import PrivPol from "./pages/PrivPol";

//Google Analytics
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

///////  REACT-BOOTSTRAP STYLING ///////////
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const analytics = Analytics({
    app: "awesome-app",
    plugins: [
      googleAnalytics({
        measurementIds: ["G-7L5DCV0S50"],
      }),
    ],
  });
  return (
    <Router>
      <div className="App">
        <App_Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/thank-you" element={<Dashboard />} />
          <Route path="/user" element={<UserDashboard />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-of-service" element={<TOS />} />
          <Route path="/privacy-policy" element={<PrivPol />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
